.datePicker{
    z-index: 10000;
    background-color:#EBEBEB;
    font-family:Inter;
    font-size: 0.8rem;
    text-align:left;
    color:#333;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    width: 40rem;
    /* margin: 0 1rem; */
}


.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    margin: auto 0;
}

.react-datepicker-wrapper{
    margin: auto 1rem;
    width: 15rem;
}