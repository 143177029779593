
  
  /**
  TODO: figrue this shit out. Absolute positioning required
  tuning the box alot which may get spoiled on other screen
  sizes. 
  reference: https://codesandbox.io/s/search-select-forked-g14kg?file=/src/styles.css
   * Main wrapper
   */
  .select-search {
    width:7vw;
    position: absolute;
    /* top:100px; */
    /* z-index: 100; */
    /* font-family: "Nunito Sans", sans-serif; */
    box-sizing: border-box;
    margin-top:-12px;
  }
  
  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }
  
  /**
  * Value wrapper
  */
  .select-search__value {
    position: relative;
    z-index: 1;
  }
  
  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(30%);
    right: 1rem;
    width: 0.5rem;
    height: 0.5rem;
  }
  
  /**
  * Input
  */
  .select-search__input {
    display: block;
    /* height: 36px; */
    width: 100%;
    height: 80%;
    padding: 0.3rem;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    outline: none;
    /* font-family: "Noto Sans", sans-serif; */
    font-size: 0.8rem;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
  }
  
  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }
  
  /**
  * Options wrapper
  */
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }
  
  /**
  * Options
  */
  .select-search__options {
    /* list-style: none; */
    /* position: absolute; */
    /* top:-100px; */
    /* left:50%; */
    z-index: 200;
    padding: 0px;
    height:9rem;
    overflow: auto;
    /* width:100%; */
  }
  
  /**
  * Option row
  */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }
  
  /**
  * Option
  */
  .select-search__option,
  .select-search__not-found {
    display: block;
    /* position: absolute; */
    z-index: 2; 
    /* height: 36px; */
    width: 100%;
    padding: 0.3rem;
    background: #fff;
    border: none;
    outline: none;
    /* font-family: "Noto Sans", sans-serif; */
    font-size: 0.8rem;
    text-align: left;
    cursor: pointer;
  }
  
  .select-search--multiple .select-search__option {
    height: 48px;
  }
  
  .select-search__option.is-selected {
    background: '#1abc9c';
  }
  
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
  }
  
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: '#1abc9c';

  }
  
  /**
  * Group
  */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }
  
  
  
  /**
  * Modifiers
  */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }
  
  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }
  
  .select-search--multiple .select-search__input {
    cursor: initial;
  }
  
  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }
  
  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }
  
  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: '#1abc9c';
  }
  
  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    /* display:block; */
    z-index: 2;
    top: 2rem;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }
  
  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }
  
  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
  
  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFAF59;
    color: #FFAF59;
    box-shadow: 9999px 0 0 -5px #FFAF59;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
  }
  
  .dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFAF59;
    color: #FFAF59;
  }
  
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #FFAF59;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #FFAF59;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
  
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #FFAF59;
    }
    30% {
      box-shadow: 9984px 0 0 2px #FFAF59;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #FFAF59;
    }
  }
  
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #FFAF59;
    }
    30% {
      box-shadow: 9999px 0 0 2px #FFAF59;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #FFAF59;
    }
  }
  
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #FFAF59;
    }
    30% {
      box-shadow: 10014px 0 0 2px #FFAF59;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #FFAF59;
    }
  }

  /* .dot-pulse{
    position: absolute;
    top:50%;
    left:50%;
  }
   */

   .dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #FFAF59, 12.72984px -12.72984px 0 0 #FFAF59, 18px 0 0 0 #FFAF59, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
    animation: dotSpin 1.5s infinite linear;
  }
  
  @keyframes dotSpin {
    0%,
    100% {
      box-shadow: 0 -18px 0 0 #FFAF59, 12.72984px -12.72984px 0 0 #FFAF59, 18px 0 0 0 #FFAF59, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    12.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 0 #FFAF59, 18px 0 0 0 #FFAF59, 12.72984px 12.72984px 0 0 #FFAF59, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    25% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #FFAF59, 12.72984px 12.72984px 0 0 #FFAF59, 0 18px 0 0 #FFAF59, -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    37.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #FFAF59, 0 18px 0 0 #FFAF59, -12.72984px 12.72984px 0 0 #FFAF59, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    50% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #FFAF59, -12.72984px 12.72984px 0 0 #FFAF59, -18px 0 0 0 #FFAF59, -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    62.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #FFAF59, -18px 0 0 0 #FFAF59, -12.72984px -12.72984px 0 0 #FFAF59;
    }
    75% {
      box-shadow: 0 -18px 0 0 #FFAF59, 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #FFAF59, -12.72984px -12.72984px 0 0 #FFAF59;
    }
    87.5% {
      box-shadow: 0 -18px 0 0 #FFAF59, 12.72984px -12.72984px 0 0 #FFAF59, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #FFAF59;
    }
  }
  